class ZIDXSavedListings implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxSavedListingsContainer";
    }

    getMatchURL() {
        return "/saved-listings/";
    }

    render() {
        window.ZIDXOptions.loadFunctions.push(() => {
            const listingIds = SavedListingRepository.getInstance().getListingIds();
            const obj = {
                id: "ajaxGetListings",
                method: "get",
                postObj: {},
                ignoreOldRequests: false,
                callback: (r: string) => {
                    let rs=JSON.parse(r);
                    if(!rs.success){
                        console.log(rs.errorMessage);
                        return;
                    }
                    const listings: Array<ZIDXListingSummary> = JSON.parse(rs.data);

                    const listingsContainer = ZIDX.$('#savedListingsContainer');
                    listingsContainer.empty();
                    if(listings.length==0){
                        listingsContainer.append(`<div><p>You have no saved listings</p><p><a href="${ZIDX.getPrefixURL("/")}" class="zidxButton"">Search Listings</a></p></div>`);
                    }
                    for (let listing of listings) {
                        const link = window.zidxBase.getListingURL(listing);
                        const resultsContainer = ZIDX.$('<a></a>').addClass('zidxSearchResultListingContainer zidxSavedListingContainer');
                        // resultsContainer.on('click', window.zidxBase.onSearchResultClick);
                        resultsContainer.attr("data-id", listing.id);
                        // resultsContainer.attr("details-link", link);
                        const content = this.renderListingThumbnailDetail(listing);
                        resultsContainer.html(content);
                        listingsContainer.append(resultsContainer);
                    }

                    window.zidxBase.bindSaveButtons();
                },
                errorCallback: function (r: string) {
                    console.log(r)
                },
                url: ZIDX.getPrefixURL("/listings/?listing_ids=" + listingIds.join(","))
            };
            ZIDX.ajaxRequest(obj);
        })
    }

    renderListingThumbnailDetail(ss: ZIDXListingSummary): string {
        let arrHTML = [];
        const isSaved = SavedListingRepository.getInstance().isSaved(ss.id);

        arrHTML.push("<div title=\"View Listing\" class=\"zidxSearchDetailImage");
        arrHTML.push("\" style=\"background-image:url('" + ss.photo + "');\"");
        arrHTML.push("><span>" + window.zidxBase.htmlEditFormat("Photo of " + ss.address) + "</span></div>\n");

        arrHTML.push("<div class=\"zidxSearchDetailPrice\">");
        if (ss.price != 0) {
            arrHTML.push("$" + window.zidxBase.numberFormat(ss.price));
        }
        arrHTML.push("</div><div class=\"zidxSearchDetailSQFT\">");
        if (ss.sqft != 0) {
            arrHTML.push(ss.sqft + " SQFT");
        }
        arrHTML.push("</div><div class=\"zidxSearchDetailBeds\">");
        if (ss.bedrooms != 0) {
            arrHTML.push(ss.bedrooms + " Beds");
        }
        arrHTML.push("</div><div class=\"zidxSearchDetailBaths\">");
        if (ss.bathrooms != 0) {
            arrHTML.push(ss.bathrooms + " Baths");
        }
        if (ss.halfBaths != 0) {
            arrHTML.push("<br>" + ss.halfBaths + " Half Baths &nbsp; ");
        }
        arrHTML.push("</div>\n" +
            "            <div class=\"zidxSearchDetailType\">\n" +
            "            " + ss.type + "\n" +
            "        </div>\n" +
            "        <div class=\"zidxSearchDetailStatus\">\n" +
            "            " + ss.listStatus + "\n" +
            "        </div>\n" +
            "        <div class=\"zidxSearchDetailAddress\">\n" +
            "            " + ss.address + "<br>\n" +
            "        " + ss.city + ", " + ss.state + " " + ss.postalCode + "\n" +
            "        </div>");//\n" +
        // "        <div class=\"zidxSearchDetailButtons\">\n" +
        // "        <a href=\""+link+"\" class=\"zidxSearchResultListingButton\">\n" +
        // "            View Listing\n" +
        // "        </a>\n" +

        arrHTML.push(`
                    <div class=\"zidxSearchDetailSaveButton\">
                        <i title=\"Save property\" data-id='${ss.id}' saved='${isSaved}' class=\"fa ${isSaved ? 'fa-heart' : 'fa-heart-o'} zidxSearchResultSave\" aria-hidden=\"true\"></i>
                    </div>
            `);
        // "        </div>");
        return arrHTML.join("");
    }
}